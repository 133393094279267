/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Form, FormCheck, Modal } from 'react-bootstrap'
import { useGetListingLotSkusQuery, useUploadSkuBulkBidMutation } from '../../../components/api/BuyerApi'
import { CreateFileSelect } from '../../../components/Form'
import { hideNull } from '../../../components/hideNull'
import { skuToDescription } from '../../../components/skuToDescription'
import Spinner from '../../../components/spinner'
import { NOOP, quickAndDirtyCSV } from '../../../components/utils'

const isUndef = o => typeof o === 'undefined'

/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '346', listingLotId = '1260', listingLots = [] } = props

  // query
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId, listingLots, isVendor: false }) // N.B. from this page, we are always the buyer!
  const uploadSkuBulkBidMutation = useUploadSkuBulkBidMutation()
  const { isSuccess = false, data: listingLotSkus = {} } = listingLotSkusQuery
  // refs
  const formRef = React.useRef()

  // state
  const [file, setFile] = React.useState(undefined)
  const [acceptTerms, setAcceptTerms] = React.useState(false)
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [bulkbidFileHref, setBulkbidFileHref] = React.useState('#')

  React.useEffect(() => {
    if (isSuccess) {
      // convert to csv...
      const header = quickAndDirtyCSV([
        'Lot',
        'listing_lot_sku_id',
        'Description',
        'Grade',
        'Quantity',
        'unit_price'
      ])
      const body = listingLotSkus.listing_lot.listing_lot_skus.map(sku => {
        return quickAndDirtyCSV([
          !isUndef(sku.listing_lot_number) ? sku.listing_lot_number : sku.lot_number,
          sku.listing_lot_sku_id,
          skuToDescription(sku),
          hideNull(sku.grade),
          hideNull(sku.qty),
          hideNull(sku.buyer_bid)
        ])
      }).join('') + '\n'
      const csv = '\uFEFF' + header + body
      setBulkbidFileHref('data:text/csv;charset=utf-8,' + encodeURI(csv))
    }
  }, [isSuccess, listingLotSkus, setBulkbidFileHref])

  // functions
  const onHide = () => {
    clearFile()
    setOpenModal(false)
    setFormSubmitted(false)
    setAcceptTerms(false)
    uploadSkuBulkBidMutation.reset()
  }
  const internalOnSave = () => {
    setFormSubmitted(true)
    if (acceptTerms) {
      uploadBulkBidFile()
      clearFile()
    }
  }

  const clearFile = () => {
    setFile(undefined)
    // N.B. the only way to reliably clear the input[type=file]!
    formRef.current.reset()
  }

  const onChangeFile = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  /**
   * Upload the product manifest file.
   */
  const uploadBulkBidFile = () => {
    if (typeof file !== 'undefined') {
      console.debug('Sending benchmark to backend...')
      uploadSkuBulkBidMutation.mutate({
        listingId,
        listingLotId,
        formData: {
          'listing[bulk_bid_csv_file]': file
        }
      })
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
            <Modal.Body>
                <h5 className="m-0 mb-3">Bulk bid</h5>

                <p>Submit your bids by filling in and uploading the <a
                href={bulkbidFileHref}
                download={`bulkbid-listing-${listingId}-lot-${listingLotId}.csv`}
                target="_blank" rel="noreferrer"
                className="font-weight-bold text-capitalize"
                >bulk bid template</a>.</p>

                {/* choose benchmark file */}
                <form ref={formRef}>
                  <CreateFileSelect
                    label="Upload bulk bid file (.CSV format only)"
                    value={file}
                    onChange={e => onChangeFile(e)}
                    />

                </form>

                <Form noValidate validated={false}>
                    <div className={`d-flex align-items-center rounded p-3 border-base ${formSubmitted ? (acceptTerms ? 'border-success' : 'border-danger') : 'border'}`}>
                      <FormCheck
                        type="checkbox"
                        id={'custom-checkbox'}
                        // label={'Check this custom checkbox'}
                        className="pr-3"
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                        isInvalid={formSubmitted && !acceptTerms}
                        />
                      I acknowledge that all bids listed on my product manifest will be submitted and cannot be retracted.
                    </div>
                    { formSubmitted && !acceptTerms && (<>
                      <div className="form-check-input is-invalid" />
                      <Form.Control.Feedback type="invalid">You must agree before submitting.</Form.Control.Feedback>
                    </>) }

                  </Form>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={typeof file === 'undefined'}>
                        {uploadSkuBulkBidMutation.isLoading ? <Spinner /> : <span>Submit</span>}
                    </Button>
                </div>

                { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>}

                { uploadSkuBulkBidMutation.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Submit successful!</Alert> }
                { uploadSkuBulkBidMutation.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error placing bulk bid. ' + (uploadSkuBulkBidMutation.error.response.data.message || uploadSkuBulkBidMutation.error.message)}</Alert> }
                { formSubmitted && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error retrieving data. ' + listingLotSkusQuery.error.message}</Alert> }

                {process.env.NODE_ENV !== 'production' && <pre>acceptTerms={JSON.stringify(acceptTerms, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>formSubmitted={JSON.stringify(formSubmitted, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>bulkbidFileHref={JSON.stringify(bulkbidFileHref, null, 2)}</pre>}
                {process.env.NODE_ENV !== 'production' && <pre>listingLotSkusQuery={JSON.stringify(listingLotSkusQuery, null, 2)}</pre>}
            </Modal.Body>
        </Modal>

      </>
}

export default Page
